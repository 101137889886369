import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <h2>Not Found</h2>
    <p>You just hit a safari that doesn&#39;t exist... <Link to='/'>return to home</Link>.</p>
  </Layout>
)

export default NotFoundPage
